import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

let Plyr;

export default function VideoPlayer({ className, play, ...props }) {
  const video = useRef();
  const reset = () => video.current.restart();
  useEffect(() => {
    // eslint-disable-next-line global-require
    Plyr = require('react-plyr').default;
  });

  useEffect(() => {
    if (play) video.current.play();
  }, [play]);

  return (
    <div className={className}>
      {Plyr ? (
        <Plyr
          onEnd={() => reset()}
          type="vimeo"
          ref={video}
          controls={['play-large', 'play', 'progress', 'mute', 'fullscreen']}
          disableContextMenu
          hideControls
          {...props}
        />
      ) : (
        <div>Loading video...</div>
      )}
    </div>
  );
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  play: PropTypes.bool,
};
